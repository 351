import React, { useState, useMemo } from 'react';
import { MagnifyingGlassIcon, ArrowPathIcon, SparklesIcon } from '@heroicons/react/24/outline';
import { useImportedData } from './ImportedData';
import { useProductEnrichment } from './Enrichment';
import { useEnrichmentConfig } from './EnrichmentConfig';
import { EnrichmentActionToolbar } from './EnrichmentActionToolbar';
import { ColumnIdentifier, ImageSearchResult } from './types';
import clsx from 'clsx';

export function EnrichmentReviewStep() {
    const [searchTerm, setSearchTerm] = useState('');
    const [selectedRowId, setSelectedRowId] = useState<string | null>(null);

    const { activeImportedData, activeFileName, getProductRowIdentifiers } = useImportedData();
    const { enrichmentGroups } = useEnrichmentConfig();
    const { enrichmentResults, updateFieldValue, enrichProductRow } = useProductEnrichment();

    const activeFileResults = activeFileName ? enrichmentResults[activeFileName] : undefined;

    const rows = useMemo(() => {
        if (!activeImportedData || !activeFileResults) return [];

        return activeImportedData.data
            .filter(row => {
                const matchesSearch = searchTerm === '' ||
                    Object.values(getProductRowIdentifiers(row.id)).some(value =>
                        value.value.toLowerCase().includes(searchTerm.toLowerCase())
                    );
                return matchesSearch;
            })
            .map(row => ({
                id: row.id,
                identifiers: getProductRowIdentifiers(row.id),
                result: activeFileResults[row.id],
                isEnriched: !!activeFileResults[row.id]?.data,
                isLoading: activeFileResults[row.id]?.loading || false
            }));
    }, [activeImportedData, activeFileResults, searchTerm, getProductRowIdentifiers]);

    const selectedRow = selectedRowId ? rows.find(row => row.id === selectedRowId) : null;

    const formatIdentifiers = (identifiers: ColumnIdentifier[]) => {
        return identifiers.map(id => id.value).join(' - ');
    };

    return (
        <div className="flex flex-col h-full">
            <div className="flex-none px-6 py-4 border-b border-gray-200">
                <EnrichmentActionToolbar />
            </div>

            <div className="flex flex-1 overflow-hidden">
                {/* Left Panel - Product List */}
                <div className="w-1/3 border-r border-gray-200 flex flex-col bg-white overflow-hidden">
                    <div className="flex-none p-4 border-b border-gray-200">
                        <div className="relative">
                            <input
                                type="text"
                                placeholder="Search products..."
                                value={searchTerm}
                                onChange={(e) => setSearchTerm(e.target.value)}
                                className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                            />
                            <MagnifyingGlassIcon className="absolute left-3 top-2.5 h-5 w-5 text-gray-400" />
                        </div>
                        <div className="mt-2 text-sm text-gray-500">
                            {rows.length} products found
                        </div>
                    </div>

                    <div className="overflow-auto flex-1">
                        <div className="divide-y divide-gray-200">
                            {rows.map((row) => (
                                <button
                                    key={row.id}
                                    onClick={() => row.isEnriched && setSelectedRowId(row.id)}
                                    disabled={!row.isEnriched}
                                    className={clsx(
                                        'w-full text-left p-4 relative',
                                        selectedRowId === row.id ? 'bg-blue-50' : 'hover:bg-gray-50',
                                        !row.isEnriched && 'opacity-50 cursor-not-allowed'
                                    )}
                                >
                                    <div className="flex items-center justify-between">
                                        <div className="flex-1">
                                            <div className="font-medium">
                                                {formatIdentifiers(row.identifiers)}
                                            </div>
                                            {row.result?.error && (
                                                <div className="text-sm text-red-600 mt-1">{row.result.error}</div>
                                            )}
                                        </div>
                                        {!row.isEnriched && !row.isLoading && (
                                            <button
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    if (activeFileName) {
                                                        enrichProductRow(activeFileName, row.id, {
                                                            enrichmentGroups,
                                                            identifiers: row.identifiers,
                                                            sourceBlacklist: [],
                                                            sourceLimit: 3,
                                                            sources: []
                                                        });
                                                    }
                                                }}
                                                className="ml-2 p-1 text-blue-500 hover:text-blue-600 rounded-full hover:bg-blue-50"
                                                title="Generate enrichment"
                                            >
                                                <SparklesIcon className="w-5 h-5" />
                                            </button>
                                        )}
                                    </div>
                                    {row.isLoading && (
                                        <div className="absolute inset-0 bg-black bg-opacity-10 flex items-center justify-center">
                                            <div className="bg-white rounded-lg px-3 py-1 text-sm font-medium text-gray-600 shadow-sm">
                                                Generating...
                                            </div>
                                        </div>
                                    )}
                                </button>
                            ))}
                        </div>
                    </div>
                </div>

                {/* Right Panel - Enrichment Details */}
                <div className="flex-1 flex flex-col bg-white overflow-hidden">
                    {selectedRow ? (
                        <>
                            <div className="flex-none px-6 py-4 border-b border-gray-200">
                                <div className="flex justify-between items-center">
                                    <h3 className="text-lg font-medium">
                                        Product Details
                                    </h3>
                                    <button
                                        onClick={() => {
                                            if (activeFileName && selectedRow) {
                                                enrichProductRow(activeFileName, selectedRow.id, {
                                                    enrichmentGroups,
                                                    identifiers: selectedRow.identifiers,
                                                    sourceBlacklist: [],
                                                    sourceLimit: 3,
                                                    sources: []
                                                });
                                            }
                                        }}
                                        disabled={selectedRow.isLoading}
                                        className={clsx(
                                            "flex items-center px-3 py-2 text-sm font-medium rounded-md transition-colors",
                                            selectedRow.isLoading
                                                ? "bg-gray-100 text-gray-400 cursor-not-allowed"
                                                : "text-gray-700 bg-white border border-gray-300 hover:bg-gray-50"
                                        )}
                                    >
                                        <ArrowPathIcon className={clsx(
                                            "w-4 h-4 mr-2",
                                            selectedRow.isLoading && "animate-spin"
                                        )} />
                                        {selectedRow.isLoading ? "Regenerating..." : "Re-enrich All"}
                                    </button>
                                </div>
                            </div>

                            <div className="overflow-auto flex-1">
                                <div className="p-6">
                                    {selectedRow.isLoading && (
                                        <div className="absolute inset-0 bg-white bg-opacity-50 flex items-center justify-center z-10">
                                            <div className="bg-white rounded-lg px-4 py-2 shadow-sm border border-gray-200">
                                                <div className="flex items-center space-x-3">
                                                    <ArrowPathIcon className="w-5 h-5 text-blue-500 animate-spin" />
                                                    <span className="text-sm font-medium text-gray-900">Regenerating enrichment...</span>
                                                </div>
                                            </div>
                                        </div>
                                    )}

                                    <div className="space-y-8">
                                        {/* Product Images */}
                                        {selectedRow.result.data?.images && selectedRow.result.data.images.length > 0 && (
                                            <div className="space-y-4">
                                                <h4 className="text-base font-medium">Product Images</h4>
                                                <div className="grid grid-cols-4 gap-4">
                                                    {selectedRow.result.data.images.map((image: ImageSearchResult) => (
                                                        <a
                                                            key={image.contentUrl}
                                                            href={image.hostPageUrl}
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                            className="group relative aspect-square overflow-hidden rounded-lg border border-gray-200 hover:border-blue-500"
                                                        >
                                                            <img
                                                                src={image.thumbnailUrl}
                                                                alt={image.name}
                                                                className="h-full w-full object-cover object-center group-hover:opacity-90"
                                                            />
                                                            <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-40 opacity-0 group-hover:opacity-100 transition-opacity">
                                                                <span className="text-white text-xs text-center px-2">
                                                                    {image.width}x{image.height}
                                                                </span>
                                                            </div>
                                                        </a>
                                                    ))}
                                                </div>
                                            </div>
                                        )}

                                        {/* Enrichment Groups */}
                                        <div className="space-y-8">
                                            {enrichmentGroups.map((group) => (
                                                <div key={group.groupName} className="space-y-4">
                                                    <h4 className="text-base font-medium">{group.groupName}</h4>
                                                    <div className="space-y-6">
                                                        {group.fields.map((field) => {
                                                            const fieldValue = selectedRow.result.data?.groups
                                                                .find(g => g.groupName === group.groupName)
                                                                ?.fields.find(f => f.fieldName === field.fieldName)
                                                                ?.values[0];

                                                            return (
                                                                <EnrichmentField
                                                                    key={field.fieldName}
                                                                    field={{
                                                                        name: field.fieldName,
                                                                        value: fieldValue?.value != null ? String(fieldValue.value) : ''
                                                                    }}
                                                                    onUpdateValue={(value) => {
                                                                        if (activeFileName) {
                                                                            updateFieldValue(
                                                                                activeFileName,
                                                                                selectedRow.id,
                                                                                group.groupName,
                                                                                field.fieldName,
                                                                                value
                                                                            );
                                                                        }
                                                                    }}
                                                                />
                                                            );
                                                        })}
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </>
                    ) : (
                        <div className="flex items-center justify-center h-full text-gray-500">
                            Select a product to view and edit enrichment details
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}

type EnrichmentFieldProps = {
    field: {
        name: string;
        value: string;
    };
    onUpdateValue: (value: string) => void;
};

function EnrichmentField({ field, onUpdateValue }: EnrichmentFieldProps) {
    const [isEditing, setIsEditing] = useState(false);
    const [editValue, setEditValue] = useState(field.value);
    const textareaRef = React.useRef<HTMLTextAreaElement>(null);

    // Move cursor to end when editing starts
    React.useEffect(() => {
        if (isEditing && textareaRef.current) {
            const length = textareaRef.current.value.length;
            textareaRef.current.setSelectionRange(length, length);
        }
    }, [isEditing]);

    return (
        <div className="flex items-start gap-4">
            <div className="flex-1">
                <label className="block text-sm font-medium text-gray-700 mb-1">
                    {field.name}
                </label>
                {isEditing ? (
                    <textarea
                        ref={textareaRef}
                        value={editValue}
                        onChange={(e) => setEditValue(e.target.value)}
                        onBlur={() => {
                            setIsEditing(false);
                            if (editValue !== field.value) {
                                onUpdateValue(editValue);
                            }
                        }}
                        className="w-full p-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                        rows={3}
                        autoFocus
                    />
                ) : (
                    <div
                        onClick={() => {
                            setIsEditing(true);
                            setEditValue(field.value);
                        }}
                        className="p-2 border border-gray-300 rounded-md min-h-[4rem] cursor-text hover:border-gray-400"
                    >
                        {field.value || <span className="text-gray-400">Click to edit...</span>}
                    </div>
                )}
            </div>
        </div>
    );
} 