import React, { useState, useEffect } from 'react';
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/24/outline';
import { FileUpload } from '../../../components';
import { useImportedData } from './ImportedData';
import { EnrichmentConfigPanelFields } from './EnrichmentConfigPanelFields';
import { EnrichmentConfigPanelProduct } from './EnrichmentConfigPanelProduct';
import { EnrichmentConfigPanelSources } from './EnrichmentConfigPanelSources';
import { EnrichmentDataTable } from './EnrichmentDataTable';
import { EnrichmentReviewStep } from './EnrichmentReviewStep';
import { useEnrichmentConfig } from './EnrichmentConfig';
import { useProductEnrichment } from './Enrichment';
import clsx from 'clsx';

type WizardStep = {
    title: string;
    description: string;
    component: React.ReactNode;
    isComplete: boolean;
};

export function EnrichmentWizard() {
    const [currentStepIndex, setCurrentStepIndex] = useState(0);
    const { setFiles, importedData, activeImportedData, activeFileName, setActiveFileName, identifierHeadersSelected } = useImportedData();
    const { enrichmentGroups, sources } = useEnrichmentConfig();
    const { enrichmentResults } = useProductEnrichment();
    const [hasAutoAdvanced, setHasAutoAdvanced] = useState(false);

    // Auto-advance to identifier selection only when file is first selected
    useEffect(() => {
        if (currentStepIndex === 0 && activeFileName && !hasAutoAdvanced) {
            setCurrentStepIndex(1);
            setHasAutoAdvanced(true);
        }
    }, [activeFileName, currentStepIndex, hasAutoAdvanced]);

    // Reset auto-advance when file changes
    useEffect(() => {
        setHasAutoAdvanced(false);
    }, [activeFileName]);

    const steps: WizardStep[] = [
        {
            title: 'Upload Dataset',
            description: 'Upload your CSV file to enrich',
            component: (
                <div className="p-6">
                    <div className="space-y-6">
                        {activeFileName ? (
                            <div className="space-y-4">
                                <div className="flex items-center justify-between">
                                    <div>
                                        <h3 className="text-sm font-medium text-gray-900">Current Dataset</h3>
                                        <p className="text-sm text-gray-500 mt-1">
                                            {activeFileName}
                                        </p>
                                    </div>
                                    <button
                                        onClick={() => setActiveFileName(null)}
                                        className="text-sm font-medium text-blue-600 hover:text-blue-500"
                                    >
                                        Change Dataset
                                    </button>
                                </div>
                                <div className="text-sm text-gray-600 bg-blue-50 px-4 py-3 rounded-lg">
                                    You can proceed to the next step to select which columns identify your products,
                                    or choose a different dataset to enrich.
                                </div>
                            </div>
                        ) : (
                            <div className="space-y-4">
                                <div>
                                    <h3 className="text-sm font-medium text-gray-900">Upload a Dataset</h3>
                                    <p className="text-sm text-gray-500 mt-1">
                                        Choose a CSV file containing the products you want to enrich.
                                    </p>
                                </div>
                                <FileUpload
                                    onFileAdd={(newFiles) => {
                                        setFiles(newFiles);
                                        if (newFiles.length > 0) {
                                            setActiveFileName(newFiles[0].name);
                                        }
                                    }}
                                    accept=".csv"
                                />
                                {Object.keys(importedData).length > 0 && (
                                    <div className="text-sm text-gray-600 bg-blue-50 px-4 py-3 rounded-lg">
                                        Or select a previously uploaded file:
                                        <div className="mt-2 space-y-2">
                                            {Object.keys(importedData).map(fileName => (
                                                <button
                                                    key={fileName}
                                                    onClick={() => setActiveFileName(fileName)}
                                                    className="block w-full text-left px-3 py-2 text-sm text-gray-700 hover:bg-blue-100 rounded-md"
                                                >
                                                    {fileName}
                                                </button>
                                            ))}
                                        </div>
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                </div>
            ),
            isComplete: !!activeFileName
        },
        {
            title: 'Select Identifiers',
            description: 'Choose the columns that identify your products',
            component: activeImportedData && (
                <div className="p-6">
                    <div className="space-y-4">
                        <p className="text-sm text-gray-600">
                            Select the column headers that identify your products. These will be used to generate search queries.
                        </p>
                        <EnrichmentDataTable
                            headers={activeImportedData.headers}
                            rows={activeImportedData.data}
                            enrichmentDisabled={!identifierHeadersSelected}
                        />
                    </div>
                </div>
            ),
            isComplete: identifierHeadersSelected
        },
        {
            title: 'Configure Product',
            description: 'Configure the product settings for enrichment',
            component: (
                <div className="p-6">
                    <EnrichmentConfigPanelProduct />
                </div>
            ),
            isComplete: true
        },
        {
            title: 'Configure Sources',
            description: 'Select and configure the data sources for enrichment',
            component: (
                <div className="p-6">
                    <EnrichmentConfigPanelSources />
                </div>
            ),
            isComplete: sources.length > 0
        },
        {
            title: 'Configure Fields',
            description: 'Select the fields you want to enrich',
            component: (
                <div className="p-6">
                    <EnrichmentConfigPanelFields />
                </div>
            ),
            isComplete: enrichmentGroups.some(group => group.fields.length > 0)
        },
        {
            title: 'Review & Enrich',
            description: 'Review and modify enrichment results',
            component: <EnrichmentReviewStep />,
            isComplete: Object.keys(enrichmentResults).length > 0
        }
    ];

    const currentStep = steps[currentStepIndex];
    const canGoNext = currentStep.isComplete && currentStepIndex < steps.length - 1;
    const canGoPrevious = currentStepIndex > 0;

    return (
        <div className="flex flex-col h-full bg-gray-50 relative">
            {/* Header with Steps */}
            <div className="bg-white border-b border-gray-200 sticky top-0 z-10">
                <div className="px-6 py-4">
                    <div className="flex items-center justify-between">
                        <div className="flex-1">
                            <h2 className="text-lg font-medium text-gray-900">{currentStep.title}</h2>
                            <p className="mt-1 text-sm text-gray-500">{currentStep.description}</p>
                        </div>
                        <div className="flex items-center gap-3 ml-8">
                            {steps.map((step, index) => {
                                const isActive = index === currentStepIndex;
                                const isComplete = index < currentStepIndex;
                                const isClickable = index <= currentStepIndex || steps[index - 1]?.isComplete;

                                return (
                                    <button
                                        key={step.title}
                                        onClick={() => isClickable && setCurrentStepIndex(index)}
                                        disabled={!isClickable}
                                        className={clsx(
                                            'w-2 h-2 rounded-full transition-all',
                                            isClickable ? 'cursor-pointer' : 'cursor-not-allowed',
                                            isActive ? 'w-6 bg-blue-500' :
                                                isComplete ? 'bg-blue-500' : 'bg-gray-200'
                                        )}
                                        title={step.title}
                                    />
                                );
                            })}
                        </div>
                    </div>
                </div>
            </div>

            {/* Content Area */}
            <div className="flex-1 overflow-auto pb-[72px]">
                {currentStep.component}
            </div>

            {/* Fixed Footer Navigation */}
            <div className="bg-white border-t border-gray-200 fixed bottom-0 right-0 md:left-[256px] left-0 z-10">
                <div className="px-6 py-4 flex justify-between items-center">
                    <button
                        onClick={() => setCurrentStepIndex(currentStepIndex - 1)}
                        disabled={!canGoPrevious}
                        className={clsx(
                            'px-6 py-2 text-sm font-medium rounded-lg transition-all',
                            canGoPrevious
                                ? 'text-gray-600 hover:text-gray-900 hover:bg-gray-100'
                                : 'text-gray-300 cursor-not-allowed'
                        )}
                    >
                        <span className="flex items-center">
                            <ChevronLeftIcon className="w-4 h-4 mr-1" />
                            Previous
                        </span>
                    </button>

                    <div className="flex items-center gap-4">
                        <span className="text-sm text-gray-500">
                            Step {currentStepIndex + 1} of {steps.length}
                        </span>
                        <button
                            onClick={() => setCurrentStepIndex(currentStepIndex + 1)}
                            disabled={!canGoNext}
                            className={clsx(
                                'px-6 py-2 text-sm font-medium rounded-lg transition-all',
                                canGoNext
                                    ? 'bg-blue-500 text-white hover:bg-blue-600'
                                    : 'bg-gray-100 text-gray-400 cursor-not-allowed'
                            )}
                        >
                            <span className="flex items-center">
                                Next
                                <ChevronRightIcon className="w-4 h-4 ml-1" />
                            </span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
} 