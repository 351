import { Outlet } from 'react-router-dom';
import { PlayIcon } from '@heroicons/react/24/outline';
import { SideMenu } from '../../components';

export const DemoWrapper = () => {
  return (
    <div className="flex flex-col md:flex-row w-full h-full overflow-hidden">
      <SideMenu
        header="Demos"
        menuItems={[
          {
            label: 'MDM',
            renderIcon: () => <PlayIcon className="w-4 h-4 mr-3" />,
            to: '/demo/mdm'
          },
          {
            label: 'Enrichment',
            renderIcon: () => <PlayIcon className="w-4 h-4 mr-3" />,
            to: '/demo/enrichment'
          }
        ]}
      />
      <div className="flex flex-col md:w-full md:h-full overflow-auto">
        <Outlet />
      </div>
    </div>
  );
};
